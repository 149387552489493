/* Consulting.css */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background: #f4f4f9;
    color: #333;
  }
  
  .consulting-container {
    position: relative; /* For the button to align relative to this container */
    text-align: center;
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out;
  }
  .title {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .mismatch-container p {
    color: #ff4c4c;
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .match-container p {
    color: #28a745;
    font-size: 1.5rem;
  }
  
  .animated-list {
    list-style: none;
    padding: 0;
  }
  
  .animated-item {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin: 5px auto;
    background: #f0f8ff;
    border: 1px solid #007bff;
    border-radius: 8px;
    max-width: 500px;
    animation: slideIn 0.5s ease-in-out forwards;
  }
  
  .animated-item:nth-child(odd) {
    background: #e0f7fa;
  }
  
  .loading-container {
    text-align: center;
    margin: 50px auto;
  }
  
  .spinner {
    margin: 20px auto;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #007bff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  .error-container {
    color: #dc3545;
    background: #f8d7da;
    padding: 20px;
    border: 1px solid #f5c6cb;
    border-radius: 8px;
    text-align: center;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 10; /* Ensure the button stays above other elements */
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  .back-button:active {
    background-color: #003f7f;
  }