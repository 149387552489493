.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #131a1b;
}

.image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 10px;
}


.Framed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
}

.upload-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.upload-button:hover {
  background-color: #0056b3;
}

.error-popup {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
}

